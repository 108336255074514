import { defineStore } from 'pinia';
import { mapDataForVehicle, getModelWithOtherModelCheck } from '@/core/quoteModel';
import { ref } from 'vue';
import {
  InsuranceTypes,
  InsuranceDurations,
  VehicleTypes,
} from '../enums/filters';
import { deepClone } from '../core/helpers';
import { useToast } from 'vue-toastification';
import {createErrorObject} from "@/api/errorHandler";

export const useQuoteStore = defineStore('quote', () => {
  // State
  // Get toast interface
  const toast = useToast();

  // The id of the quote, since the user is guest
  const fid = ref('');
  const category = ref('');

  const filtersThatRequireAPICall = [
    'vehicleType',
    'vehicleValue',
    'insuranceType',
    'insuranceDuration',
    'insuranceCovers',
    'startingDate',
    'afm',
    'email'
  ];

  const appliedFilters = ref({});

  const initialQuoteData = ref({})

  // The filters that are selected by the user
  const selectedFilters = ref({});

  // The quote object, this is the response from the API and is used to populate the offcanvas showing the quote details
  const quote = ref(null);

  // This is the quote object that is used to populate the quote details in the offcanvas
  const quoteDetailsForView = ref(null);

  const userQuoteData = ref({});

  // Actions
  const reset = () => {
    quote.value = {};
    quoteDetailsForView.value = {};
    selectedFilters.value = {};
  };

  const onChangeInsuranceType = () => {
    // If the user changes the insurance type, we need to clear some of the filters
    // 1. Covers
    selectedFilters.value.insuranceCovers = [];

    // 2. Deduction options
    selectedFilters.value.deductionOptions = [];

    // 3. Extra filters
    selectedFilters.value.extraFilters = [];
  };

  // This functions handles the quote from the server and transforms it to match the format in our vue app
  const handleQuote = (quoteData) => {
    // Here we need to transform the quote object to match the format in our vue app.
    // We have 2 sections inside the quote.
    // 1. The quote details which are just visible in the offcanvas
    // 2. The quote filters which are the current filters applied to the quote

    // Save the quote for future use
    quote.value = quoteData;

    if(category.value !== quoteData.context.classification.type){
      throw createErrorObject('Invalid Category')
    }

    // Transform the quote data to match the format in our vue app
    const transformed = transformQuote(quoteData);

    // Save the quote for future use
    quoteDetailsForView.value = transformed.quote;

    // Local filters
    // We need to keep the local filters (deduction options and extra filters) and add them to the transformed filters
    // 1. Extra filters
    // If the user has selected extra filters, we need to keep them
    if (selectedFilters.value.extraFilters) {
      transformed.filters.extraFilters = [
        ...selectedFilters.value.extraFilters,
      ];
    } else {
      transformed.filters.extraFilters = [];
    }

    // 2. Deduction options
    // If the user has selected deduction options and the insurance type has the same set of deduction options, we need to keep them
    if (selectedFilters.value.deductionOptions) {
      transformed.filters.deductionOptions = [
        ...selectedFilters.value.deductionOptions,
      ];
    } else {
      transformed.filters.deductionOptions = [];
    }

    // Used deepClone to keep reactivity
    appliedFilters.value = deepClone(transformed.filters);
    // Copy the applied filters to the selected filters
    copyAppliedFiltersToSelectedFilters();
  };

  const setStartDate = (date) => {
    selectedFilters.value.startingDate = date;
  };

  const setFilter = (propertyName, value) => {
    selectedFilters.value[propertyName] = value;
  };

  const setInitialQuote = data => initialQuoteData.value = data
  const getInitialQuote = _ => handleQuote(initialQuoteData.value)

  const copyAppliedFiltersToSelectedFilters = () => {
    selectedFilters.value = deepClone(appliedFilters.value);
  };

  const copySelectedFiltersToAppliedFilters = () => {
    appliedFilters.value = deepClone(selectedFilters.value);
  };

  const applyLocalFilters = () => {
    // Apply local filters. We just need to copy the selected filters to the applied filters
    copySelectedFiltersToAppliedFilters();
  };

  const clearLocalFilters = () => {
    // Clear local filters.
    // 1. Deduction options
    selectedFilters.value.deductionOptions = [];
    appliedFilters.value.deductionOptions = [];
    // 2. Extra filters
    selectedFilters.value.extraFilters = [];
    appliedFilters.value.extraFilters = [];
  };

  const transformQuote = (fidData) => {
    const quote = processQuote(fidData);
    const filters = processFilters(fidData);

    return {
      quote,
      filters,
    };
  };

  const processQuote = (fidData) => {
    // Call a function that Maps the data to the format we want to show depending on the vehicle type
    const {
      vehicleDetails, ownerDetails, secondDriverDetails
    } = mapDataForVehicle(fidData, fidData.context.classification.type);

    return {
      vehicleDetails,
      ownerDetails,
      secondDriverDetails,
      sideBarDetails: {
        manufacturer_text: fidData.formData.vehicle.manufacturer_text,
        model_text: getModelWithOtherModelCheck(fidData),
        manufacturing_date: new Date(fidData.formData.vehicle.manufacturing_date),
        license_plate: fidData.formData.vehicle.license_plate,
        minRecommendedValue: fidData.viewData.minRecommendedValue,
        maxRecommendedValue: fidData.viewData.maxRecommendedValue,
      }
    };
  }

  const processFilters = (fidData) => {
    const filters = {};
    // Vehicle Type
    switch (fidData.context.classification.type) {
      case 'car':
        filters.vehicleType = VehicleTypes.Car;
        break;
      case 'bike':
        filters.vehicleType = VehicleTypes.Bike;
        break;
      case 'truck':
        filters.vehicleType = VehicleTypes.Truck;
        break;
      case 'rural':
        filters.vehicleType = VehicleTypes.Rural;
        break;
      case 'taxi':
        filters.vehicleType = VehicleTypes.Taxi;
        break;
      case 'rentalcar':
        filters.vehicleType = VehicleTypes.RentalCar;
        break;
    }

    // Vehicle Value
    filters.vehicleValue = fidData.formData.vehicle.value;

    // Insurance Type
    switch (fidData.context.classification.filters.packetType) {
      case 'BASIC':
        filters.insuranceType = InsuranceTypes.Basic;
        break;
      case 'EXTRA':
        filters.insuranceType = InsuranceTypes.Extra;
        break;
      case 'PREMIUM':
        filters.insuranceType = InsuranceTypes.Premium;
        break;
    }

    // Insurance Duration
    switch (fidData.formData.contract.duration) {
      case 1:
        filters.insuranceDuration = InsuranceDurations.OneMonth;
        break;
      case 3:
        filters.insuranceDuration = InsuranceDurations.ThreeMonths;
        break;
      case 6:
        filters.insuranceDuration = InsuranceDurations.SixMonths;
        break;
      case 12:
        filters.insuranceDuration = InsuranceDurations.TwelveMonths;
        break;
      default:
        filters.insuranceDuration = InsuranceDurations.TwelveMonths;
        break;
    }

    // Insurance Covers
    filters.insuranceCovers = [];
    Object.keys(fidData.quoteData?.selectedFeatures || {}).forEach(key => {
      filters.insuranceCovers.push(key);
    });

    // Starting Date
    filters.startingDate = new Date(
      fidData.formData.contract.start_date
    ).toDateString();

    return filters;
  }

  return {
    fid,
    category,
    quote,
    userQuoteData,
    quoteDetailsForView,
    reset,
    appliedFilters,
    selectedFilters,
    applyLocalFilters,
    copyAppliedFiltersToSelectedFilters,
    copySelectedFiltersToAppliedFilters,
    setStartDate,
    // setAFM,
    // setEmail,
    setFilter,
    handleQuote,
    setInitialQuote,
    getInitialQuote,
    onChangeInsuranceType,
    filtersThatRequireAPICall,
    clearLocalFilters,
  };
});
